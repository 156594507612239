import { destroy, get, post, put } from '@/application/api/implementations/app';

/**
 * @param documentCategoryId {number}
 * @param documentType {DocumentType}
 */
export const create = (documentCategoryId, documentType) => {
  return post(`setting/candidate/document-category/${documentCategoryId}/document-type`, documentType);
};

/**
 * @param documentType {DocumentType}
 */
export const update = (documentType) => {
  return put(`setting/candidate/document-type/${documentType.id}`, documentType);
};

export const permissions = () => {
  return get('setting/candidate/document-type-permission');
};

export const show = (documentTypeId) => {
  return get(`setting/candidate/document-type/${documentTypeId}`);
};

export const remove = (documentTypeId) => {
  return destroy(`setting/candidate/document-type/${documentTypeId}`);
};
